import React, { useState, useEffect, useRef, useCallback } from "react";
import JsonForms from "../components/jsonForms";
import ThemeNavbar from "../components/themeNavbar";
import background from "../assets/images/background4.png";
import { getChildFoldersOfUploadedFolder } from "../apis/folderApis";
import { getThisUser } from "../apis/userApis";
import { getFormById } from "../apis/formsApis";
import { FaFolder, FaFolderOpen, FaRegFileLines, FaAngleRight, FaArrowUpRightFromSquare } from "react-icons/fa6";
import { getAllResponses } from "../apis/responseApis";
import { Dropdown, Table, Badge, Spinner } from "react-bootstrap";
import "../styles/ResponserPageStyles.css";
import ThemeButton from "../components/themeButton";

const BackArrowIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg"
    width="16" height="16" fill="currentColor" className="bi bi-arrow-left" viewBox="0 0 16 16">
    <path
      fillRule="evenodd"
      d="M15 8a.5.5 0 0 1-.5.5H2.707l3.147 3.146a.5.5 0 0 1-.708.708l-4-4a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 7.5H14.5A.5.5 0 0 1 15 8z"
    />
  </svg>
);


function ResponsePage() {

  const rootFolderName = "Drive";
  const rootFolderGoogleID = "***";
  const EmptyFolderStructure = {
    name: rootFolderName,
    google_drive_id: rootFolderGoogleID,
    children: []
  };

  const [selectedFormId, setSelectedFormId] = useState(null);
  const [selectedFolderGoogleId, setSelectedFolderGoogleId] = useState(null);
  const [serverError, setServerError] = useState("");
  const [folderStructure] = useState(EmptyFolderStructure);
  const [isLoading, setIsLoading] = useState(false);
  const [expandedFolders, setExpandedFolders] = useState([]);
  const [hoveredFolder, setHoveredFolder] = useState(null);
  const [profile, setProfile] = useState(null);
  const [groupFilterId, setGroupFilterId] = useState(null);
  const [userGroups, setUserGroups] = useState([]);
  const [uploadedforms, setUploadedForms] = useState([]);
  const [responses, setResponses] = useState([]);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 750);

  const [isTableLoading, setIsTableLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const reponseTableRef = useRef(null);
  const observerTarget = useRef(null);

  const fetchUserProfile = (email) => {
    console.log("Fetching profile for: " + email);
    getThisUser(email)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          setProfile(response.data.user);
          setUserGroups(response.data.user.groups);
        } else {
          setServerError(response.status);
        }
      })
      .catch((error) => {
        console.error(error);
        setServerError(503);
      });
  };

  const handleFetchError = (status) => {
    switch (status) {
      case 304:
        break;
      case 400:
        setServerError("400 Bad Request");
        break;
      case 401:
        setServerError("401 Unauthorized Access");
        break;
      case 403:
        setServerError("403 Forbidden Access");
        break;
      case 404:
        setServerError("404 Not Found");
        break;
      case 429:
        setServerError("429 Too Many Requests");
        break;
      case 500:
        setServerError("500 Internal Server Error");
        break;
      case 503:
        setServerError("503 Service Unavailable");
        break;
      default:
        setServerError("500 Internal Server Error");
        break;
    }
  };

  useEffect(() => {
    // console.log("First useEffect running");
    const userFromStorage = JSON.parse(localStorage.getItem("user"));
    if (!userFromStorage) {
      setServerError("401 Unauthorized Access"); // Set 401 if user is empty
    } else {
      fetchUserProfile(userFromStorage.email);
      fetchResponses();
      window.addEventListener("resize", handleResize);
    }
  }, []);

  const handleResize = () => {
    if (window.innerWidth < 750) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  const scrollTableRight = (tableRef) => {
    if (tableRef.current) {
      const scrollContainer = tableRef.current;
      const maxScrollLeft = scrollContainer.scrollWidth - scrollContainer.clientWidth;

      scrollContainer.scrollTo({
        left: maxScrollLeft,
        behavior: "smooth"
      });
    }
  };

  const toggleFolder = async (folderName) => {
    // console.log("folderName", folderName);
    // console.log(folderStructure);
    if (expandedFolders.includes(folderName)) {
      const newExpandedFolders = expandedFolders.filter((folder) => folder !== folderName);
      setExpandedFolders(newExpandedFolders);
    } else {
      const specificFolder = findFolderWithGoogleID(folderStructure, folderName);
      if (specificFolder && (!specificFolder.children || specificFolder.children.length === 0)) {
        await fetchFolderStructure(specificFolder);
      }
      const newExpandedFolders = [...expandedFolders, folderName];
      setExpandedFolders(newExpandedFolders);
    }
  };

  const fetchResponses = useCallback((pageNumber = 0) => {
    if (!hasMore || isTableLoading) return;

    setIsTableLoading(true);
    getAllResponses(pageNumber)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          setResponses((prevResponses) => {
            const newResponses = response.data.responses;
            const uniqueNewResponses = newResponses.filter(
              (newResponse) => !prevResponses.some((prevResponse) => prevResponse.id === newResponse.id)
            );
            return [...prevResponses, ...uniqueNewResponses];
          });
          setHasMore(response.data.pagination.total_pages > pageNumber + 1);
          setPage(pageNumber);
        } else {
          handleFetchError(response.status);
        }
      })
      .catch((error) => {
        console.error(error);
        handleFetchError(503);
      })
      .finally(() => {
        setIsTableLoading(false);
      });
  }, [hasMore, isTableLoading]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && hasMore && !isLoading) {
          fetchResponses(page + 1);
        }
      },
      { threshold: 0.1, rootMargin: "20px" }
    );

    const currentObserverTarget = observerTarget.current;

    if (currentObserverTarget) {
      observer.observe(currentObserverTarget);
    }

    return () => {
      if (currentObserverTarget) {
        observer.unobserve(currentObserverTarget);
      }
    };
  }, [fetchResponses, hasMore, isLoading, page]);

  useEffect(() => {
    if (page > 0) {
      fetchResponses(page);
    }
  }, [page, fetchResponses]);

  const findFolderWithGoogleID = (folderStructureToSearch, googleID) => {
    if (folderStructureToSearch.google_drive_id === googleID) {
      return folderStructureToSearch;
    } else if (folderStructureToSearch.children) {
      for (const child of folderStructureToSearch.children) {
        const foundFolder = findFolderWithGoogleID(child, googleID);
        if (foundFolder) {
          return foundFolder;
        }
      }
    }
    return null;
  };

  const fetchFolderStructure = async (parentFolder) => {
    setIsLoading(true);
    let parentFolderID;
    if (parentFolder.google_drive_id === "***") {
      parentFolderID = -1;
    }
    else {
      parentFolderID = parentFolder.google_drive_id;
    }

    try {
      const fetchedFolderStructure = await getChildFoldersOfUploadedFolder(parentFolderID);

      if (fetchedFolderStructure.status >= 200 && fetchedFolderStructure.status < 300) {
        parentFolder.children = fetchedFolderStructure.data;

        if (fetchedFolderStructure.data.length > 0) {
          const newFormIds = fetchedFolderStructure.data
            .filter(folder => folder.form_ids && folder.form_ids.length > 0)
            .flatMap(folder => folder.form_ids)
            .filter(formId => !uploadedforms.some(form => form.id === formId));

          for (const formId of newFormIds) {
            const formResponse = await getFormById(formId);
            if (formResponse.status >= 200 && formResponse.status < 300) {
              setUploadedForms(prevForms => [...prevForms, formResponse.data]);
            }
          }

          console.log(uploadedforms);
        }
      } else {
        setServerError(fetchedFolderStructure.status);
      }
    } catch (error) {
      console.error("Error fetching folder structure or forms:", error);
      setServerError("An error occurred while fetching data");
    } finally {
      setIsLoading(false);
    }
  };

  const renderFolderStructure = (folder, isRoot = false) => {
    // console.log(folder);
    const folderPath = `${folder.google_drive_id}`;
    const isExpanded = expandedFolders.includes(folderPath);
    const isHovered = hoveredFolder === folderPath;

    const shouldDisplayFolder = folder.google_drive_id === rootFolderGoogleID || groupFilterId === null ||
      folder.groups_id === null || (folder.group_id === groupFilterId.id);

    if (!shouldDisplayFolder && isRoot) {
      return null;
    }

    return (
      <div key={folderPath} style={{
        marginBottom: "5px", paddingLeft: "10px", borderLeft: "2px solid transparent",
      }}>
        <div
          style={{
            display: "flex", alignItems: "center", cursor: "pointer", padding: "5px", borderRadius: "3px",
            transition: "background-color 0.3s", backgroundColor: isHovered ? "#ede5e8" : "transparent"
          }}
          onMouseEnter={() => setHoveredFolder(folderPath)}
          onMouseLeave={() => setHoveredFolder(null)}
          onClick={(event) => {
            toggleFolder(folderPath);
            event.stopPropagation();
          }}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              toggleFolder(folderPath);
            }
            event.stopPropagation();
          }} role="button" tabIndex="0">
          <div style={{
            background: "none", border: "none", cursor: "pointer", marginRight: "5px",
            display: "flex", alignItems: "center", fontSize: isMobile ? "12px" : "inherit"
          }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              {isExpanded ? <FaFolderOpen /> : <FaFolder />}
              <div>
                <span style={{ marginLeft: "5px" }}>{folder.name}</span>
                {isMobile ?
                  <></>
                  :
                  <span style={{ marginLeft: "5px", fontSize: "10px", color: "gray", whiteSpace: "nowrap" }}>
                    (googleID: {folder.google_drive_id} {folder.id})
                  </span>}
                {folder.group_id && (
                  <Badge pill bg="primary" key={folder.group_id} style={{ marginLeft: "5px", fontSize: isMobile ? "10px" : "" }}>
                    {userGroups.find(group => group[0] === folder.group_id)?.[1] || ""}
                  </Badge>
                )}
              </div>
            </div>
          </div>
        </div>

        {isExpanded && folder.form_ids && folder.form_ids.length > 0 && (
          <div>
            {folder.form_ids.map((formID) => {
              const uploadedForm = uploadedforms.find(form => form.id === formID);
              // console.log(uploadedforms, formID, uploadedForm);
              return (
                <div key={formID} className="form-buttons-form-selection"
                  onClick={(event) => {
                    handleFormSelection(formID, folder.google_drive_id);
                    event.stopPropagation();
                  }}
                  onKeyDown={(event) => { if (event.key === "Enter") { handleFormSelection(formID, folder.google_drive_id); } }}
                  role="button" tabIndex="0">
                  <div style={{ fontSize: isMobile ? "12px" : "", whiteSpace: "nowrap" }}>
                    <FaRegFileLines />
                    {` ${uploadedForm ? uploadedForm.name : formID}`}
                  </div>
                </div>
              );
            })}
          </div>
        )}
        {isExpanded && folder.children && folder.children.length > 0 && (
          <div style={{ marginLeft: "20px" }}>
            {folder.children.map((child) => (
              <div key={child.google_drive_id}>
                {folderPath === rootFolderGoogleID ?
                  renderFolderStructure(child, true) :
                  renderFolderStructure(child, false)}
              </div>
            ))}
          </div>
        )}
      </div>
    );
  };

  const handleFormSelection = (formID, folderGoogleID) => {
    setSelectedFormId(formID);
    setSelectedFolderGoogleId(folderGoogleID);
  };

  const handleBackButton = () => {
    setSelectedFormId(null);
    setSelectedFolderGoogleId(null);
  };

  const handleFormResponseSubmit = async () => {
    setSelectedFormId(null);
    setSelectedFolderGoogleId(null);
    fetchResponses();
  };

  const pageStyle = {
    backgroundImage: `url(${background})`,
    backgroundRepeat: "repeat",
    backgroundSize: "150px 150px",
    minHeight: "100vh",
    paddingBottom: "25px",
  };

  return (
    <div style={pageStyle}>
      {!serverError || !profile ? (
        <>
          <ThemeNavbar />
          {!selectedFormId ? (
            <div>
              <div className="form-selection-container">
                <h1 style={{ fontSize: isMobile ? "20px" : "" }}>Forms</h1>
                <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                  <div style={{
                    display: "block", height: "50vh", width: "85vw", border: "1px solid #50011a",
                    borderBottomRightRadius: "10px", borderBottomLeftRadius: "10px", overflowY: "auto",
                    overflowX: "scroll", backgroundColor: "#f3f6f4"
                  }}>
                    <h5 style={{ backgroundColor: "#50011a", color: "white", padding: "10px", marginBlockEnd: "0px" }}>
                      <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "10px", flex: 1 }}>
                          <div style={{
                            whiteSpace: "nowrap", fontSize: isMobile ? "14px" : "",
                            overflowX: "scroll"
                          }}>Interactive Form Selection</div>
                          <Dropdown>
                            <Dropdown.Toggle variant="outline-primary"
                              style={{
                                "--bs-btn-color": "#fff",
                                "--bs-btn-border-color": "#fff",
                                "--bs-btn-hover-color": "#50011a",
                                "--bs-btn-hover-bg": "#fff",
                                "--bs-btn-hover-border-color": "#fff",
                                "--bs-btn-active-color": "#fff",
                                "--bs-btn-active-bg": "#50011a",
                                "--bs-btn-active-border-color": "#50011a",
                                "--bs-btn-active-shadow": "inset 0 3px 5px rgba(0, 0, 0, 0.125)",
                                "--bs-btn-disabled-color": "#50011a",
                                "--bs-btn-disabled-bg": "transparent",
                                "--bs-btn-disabled-border-color": "#50011a",
                                fontSize: isMobile ? "8px" : "12px"
                              }}>
                              {groupFilterId === null ? "Showing All Groups" : `Showing ${groupFilterId.name}`}
                            </Dropdown.Toggle>
                            <Dropdown.Menu style={{ maxHeight: "150px", overflowY: "auto" }}>
                              <Dropdown.Item onClick={() => setGroupFilterId(null)}
                                style={{ fontSize: isMobile ? "8px" : "" }}>All Groups</Dropdown.Item>
                              {userGroups.map((group) => (
                                <Dropdown.Item key={group.id} onClick={() => setGroupFilterId(group)}
                                  style={{ fontSize: isMobile ? "8px" : "" }}>
                                  Group {group.name}
                                </Dropdown.Item>
                              ))}
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                        {isLoading && (
                          <div style={{ textAlign: "center", marginLeft: "10px" }}>
                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            {isMobile ? <></> : <span className="sr-only">Processing...</span>}
                          </div>
                        )}
                      </div>
                    </h5>
                    <div style={{ maxHeight: "450px", overflowY: "auto", padding: "10px", paddingRight: "20px" }}>
                      {renderFolderStructure(folderStructure)}
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-response-container">
                <h1 style={{ fontSize: isMobile ? "20px" : "" }}>Dispatched Responses</h1>
                <div className="table-container" ref={reponseTableRef}>
                  {isMobile && (
                    <FaAngleRight
                      onClick={() => scrollTableRight(reponseTableRef)}
                      style={{
                        fontSize: "15px",
                        zIndex: "100",
                        position: "absolute",
                        color: "#fff",
                        left: "90%",
                        marginTop: "2px",
                      }}
                    />
                  )}
                  <Table striped bordered className="curved-table">
                    <thead style={{ fontSize: isMobile ? "14px" : "20px", height: isMobile ? "40px" : "60px" }}>
                      <tr>
                        <th style={{ backgroundColor: "#50011a", color: "white", width: "4%" }}>#</th>
                        <th style={{ backgroundColor: "#50011a", color: "white", width: "6%" }}>File ID</th>
                        <th style={{ backgroundColor: "#50011a", color: "white", width: "12%" }}>Location</th>
                        <th style={{ backgroundColor: "#50011a", color: "white", width: "40%" }}>File Name</th>
                        <th style={{ backgroundColor: "#50011a", color: "white", width: "12%" }}>File Type</th>
                        <th style={{ backgroundColor: "#50011a", color: "white", width: "13%" }}>Response Time</th>
                        <th style={{ backgroundColor: "#50011a", color: "white", width: "13%" }}>User</th>
                      </tr>
                    </thead>
                    <tbody style={{ fontSize: isMobile ? "12px" : "18px" }}>
                      {responses.map((response, index) => (
                        <tr key={response.id} ref={index === responses.length - 1 ? observerTarget : null}>
                          <td style={{ textAlign: "center" }}>{index + 1}</td>
                          <td style={{ textAlign: "center" }}>{response.id}</td>
                          <td style={{ textAlign: "center" }}>{response.folder_path}</td>
                          <td style={{ textAlign: "center" }}>
                            {response.document_title}
                            <ThemeButton variant="outline-primary"
                              style={{ marginLeft: "5px", fontSize: isMobile ? "8px" : "12px" }}
                              onClick={() => window.open(`https://drive.google.com/drive/u/0/folders/${response.folder_google_id}`, "_blank")}>
                              <FaArrowUpRightFromSquare />
                            </ThemeButton>
                          </td>
                          <td style={{ textAlign: "center" }}>
                            {response.response_type === "fileUpload" ? "User uploaded file" : "Form Response"}
                          </td>
                          <td style={{ textAlign: "center" }}>
                            <div style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
                              {new Date(response.response_time).toLocaleString("en-US", { timeZone: "America/Chicago" })}
                            </div>
                          </td>
                          <td style={{ textAlign: "center" }}>
                            <div style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
                              {response.user_name}
                              {!isMobile && (
                                <span style={{ marginLeft: "5px", fontSize: "10px", color: "gray" }}>
                                  ({response.user_email})
                                </span>
                              )}
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                  {isTableLoading && (
                    <div className="text-center mt-3">
                      <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </Spinner>
                    </div>
                  )}
                  <tr ref={observerTarget}>
                    <td colSpan="7" style={{ height: "1px" }}></td>
                  </tr>
                </div>
              </ div>
            </div>
          ) : (
            <div>
              <button className="btn btn-danger mb-3"
                onClick={handleBackButton} style={{ display: "flex", alignItems: "center", marginLeft: "5px" }}>
                <BackArrowIcon />
                <span className="ml-2">Back</span>
              </button>
              <JsonForms
                formId={selectedFormId}
                folderGoogleId={selectedFolderGoogleId}
                submitButtonFunction={handleFormResponseSubmit}
              />
            </div>
          )}
        </>
      ) : (
        <div className="alert alert-danger" role="alert">
          Server Error: {serverError}
        </div>
      )}

    </div>
  );
}

export default ResponsePage;
