const baseURL = process.env.NODE_ENV === "development" ? "http://localhost:5000" : "";


/**
 * Retrieves all groups from the server.
 *
 * @return {Promise<Object>} An object containing the status code and the data if the request is successful,
 *                           or an object with only the status code if the request fails.
 */
export const getAllGroups = async () => {
  try {
    const response = await fetch(`${baseURL}/api/groups/getAllGroups`,
      { method: "GET", credentials: "include" }
    );
    if (response.status == 200) {
      return {
        status: response.status,
        data: await response.json(),
      };
    }
    return {
      status: response.status,
      data: await response.json(),
    };
  } catch (error) {
    console.error(error);
    return {
      status: 520,
    };
  }
};

/**
 * Retrieves a group by its ID from the server.
 *
 * @param {string} groupId - The ID of the group to retrieve.
 * @return {Promise<Object>} A promise that resolves to an object with the following properties:
 *   - status: The HTTP status code of the response.
 *   - data: The parsed JSON data of the response, if the status is 200.
 * @throws {Error} If there is an error during the fetch request.
 */
export const getGroupById = async (groupId) => {
  try {
    const response = await fetch(`${baseURL}/api/groups/getGroupById?groupId=${groupId}`,
      { method: "GET", credentials: "include" }
    );
    if (response.status == 200) {
      return {
        status: response.status,
        data: await response.json(),
      };
    }
    return {
      status: response.status,
    };
  } catch (error) {
    console.error(error);
    return {
      status: 520,
    };
  }
};

/**
 * Adds a new group with the given name.
 *
 * @param {string} name - The name of the group to be added.
 * @return {Promise<Object>} An object containing the status code and the data if the request is successful,
 *                           or an object with only the status code if the request fails.
 * @throws {Error} If there is an error during the fetch request.
 */
export const addGroupToClient = async (name) => {
  console.log(name);
  try {
    const response = await fetch(`${baseURL}/api/groups/addGroupToClient?name=${name}`, {
      method: "POST",
      credentials: "include"
    });
    if (response.status === 200) {
      return {
        status: response.status,
        data: await response.json(),
      };
    }
    return {
      status: response.status,
      data: await response.json(),
    };
  } catch (error) {
    console.error(error);
    return {
      status: 520,
    };
  }
};

/**
 * Adds users to a group.
 *
 * @param {string} groupId - The ID of the group to add users to.
 * @param {Array<string>} userIds - The IDs of the users to add to the group.
 * @return {Promise<Object>} An object containing the status code and the data if the request is successful,
 *                           or an object with only the status code if the request fails.
 * @throws {Error} If there is an error during the fetch request.
 */
export const addUsersToGroup = async (groupId, userIds) => {
  try {
    const response = await fetch(`${baseURL}/api/groups/addUsersToGroup`, {
      method: "POST",
      credentials: "include",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ groupId, userIds })
    }
    );
    if (response.status == 200) {
      return {
        status: response.status,
        data: await response.json(),
      };
    }
    return {
      status: response.status,
      data: await response.json(),
    };
  } catch (error) {
    console.error(error);
    return {
      status: 520,
    };
  }
};

/**
 * Removes users from a group.
 *
 * @param {string} groupId - The ID of the group to remove users from.
 * @param {Array<string>} userIds - The IDs of the users to remove from the group.
 * @return {Promise<Object>} An object containing the status code and the data if the request is successful,
 *                           or an object with only the status code if the request fails.
 * @throws {Error} If there is an error during the fetch request.
 */
export const removeUsersFromGroup = async (groupId, userIds) => {
  try {
    const response = await fetch(`${baseURL}/api/groups/removeUsersFromGroup`, {
      method: "POST",
      credentials: "include",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ groupId, userIds })
    }
    );
    if (response.status == 200) {
      return {
        status: response.status,
        data: await response.json(),
      };
    }
    return {
      status: response.status,
      data: await response.json(),
    };
  } catch (error) {
    console.error(error);
    return {
      status: 520,
    };
  }
};

/**
 * Creates a group drive.
 *
 * @param {string} groupId - The ID of the group.
 * @return {Promise<Object>} An object containing the status code and the data if the request is successful,
 *                           or an object with only the status code if the request fails.
 * @throws {Error} If there is an error during the fetch request.
 */
export const createGroupDrive = async (groupId) => {
  try {
    const response = await fetch(`${baseURL}/api/groups/createGroupDrive?groupId=${groupId}`,
      { method: "POST", credentials: "include" }
    );
    if (response.status == 200) {
      return {
        status: response.status,
        data: await response.json(),
      };
    }
    return {
      status: response.status,
      data: await response.json()
    };
  } catch (error) {
    console.error(error);
    return {
      status: 520,
    };
  }
};

/**
 * Imports a Google Drive and assigns it to the given group.
 *
 * @param {string} driveId - The ID of the Google Drive to import.
 * @param {string} groupId - The ID of the group to assign the drive to.
 *
 * @return {Promise<Object>} An object containing the status code and the data if the request is successful,
 *                           or an object with only the status code if the request fails.
 * @throws {Error} If there is an error during the fetch request.
 */
export const importGroupDrive = async (driveId, groupId) => {
  try {
    const response = await fetch(`${baseURL}/api/groups/importGroupDrive?driveId=${driveId}&groupId=${groupId}`,
      { method: "POST", credentials: "include" }
    );
    if (response.status == 200) {
      return {
        status: response.status,
        data: await response.json(),
      };
    }
    return {
      status: response.status,
      data: await response.json()
    };
  } catch (error) {
    console.error(error);
    return {
      status: 520,
    };
  }
};

export const updateUserGroupRole = async (groupId, userId, role) => {
  try {
    const response = await fetch(`${baseURL}/api/groups/updateUserGroupRole?groupId=${groupId}&userId=${userId}&role=${role}`, {
      method: "PUT",
      credentials: "include",
      headers: { "Content-Type": "application/json" },
    }
    );
    if (response.status == 200) {
      return {
        status: response.status,
        data: await response.json(),
      };
    }
    return {
      status: response.status,
      data: await response.json(),
    };
  } catch (error) {
    console.error(error);
    return {
      status: 520,
    };
  }
};