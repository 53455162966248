import React, { useEffect, useState } from "react";
import { JsonForms } from "@jsonforms/react";
import { getFormUiJsonById } from "../apis/formsApis";
import { initiateFileUpload, uploadFileChunk, completeFileUpload, postResponse } from "../apis/responseApis";
import "bootstrap/dist/css/bootstrap.min.css";
import { materialRenderers } from "@jsonforms/material-renderers";

const CHUNK_SIZE = 5 * 1024 * 1024; // 5 MB chunks

const JsonFormsPage = ({ formId, folderGoogleId, submitButtonFunction }) => {
  const [formStructure, setFormStructure] = useState(null);
  const [schema, setSchema] = useState(null);
  const [uischema, setUischema] = useState(null);
  const [fileUploadSchema, setFileUploadSchema] = useState(null);
  const [data, setData] = useState({});
  const [formError, setFormError] = useState(null);
  const [serverError, setServerError] = useState(null);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState({});


  useEffect(() => {
    console.log("Form ID:", formId);
    console.log("Folder Google ID:", folderGoogleId);
    fetchFormUISchema();
  }, [formId, folderGoogleId]);

  const fetchFormUISchema = async () => {
    const response = await getFormUiJsonById(formId);
    if (response.status === 200) {
      const { structure, json_forms_lib_schema, json_forms_lib_ui_schema, json_forms_lib_file_upload_schema } = response.data;
      setSchema(json_forms_lib_schema);
      setUischema(json_forms_lib_ui_schema);
      setFileUploadSchema(json_forms_lib_file_upload_schema);
      setFormStructure(structure);
      setServerError(null);
    } else {
      console.log(response);
      setServerError(response.status);
    }
  };

  const handleFileChange = (event, questionNum) => {
    const files = Array.from(event.target.files);
    const fileNames = files.map(file => file.name);

    files.forEach(file => {
      console.log(questionNum, file.name);
    });
    setUploadedFiles(prevFiles => ({
      ...prevFiles,
      [questionNum]: files
    }));

    setData(prevData => ({
      ...prevData,
      ["question_" + questionNum]: fileNames
    }));

  };

  const validateFileUpload = () => {
    if (fileUploadSchema && fileUploadSchema.some(schema => schema.isRequired)) {
      const requiredQuestions = fileUploadSchema.filter(schema => schema.isRequired).map(schema => schema.questionNumber);
      const missingUploads = requiredQuestions.filter(questionNum => !uploadedFiles[questionNum] || uploadedFiles[questionNum].length === 0);

      if (missingUploads.length > 0) {
        return missingUploads.join(", ");
      }
    }
    return null;
  };

  const uploadFile = async (file, questionNum) => {
    try {
      const { uploadId } = await initiateFileUpload(file.name, file.type, file.size, folderGoogleId);

      for (let start = 0; start < file.size; start += CHUNK_SIZE) {
        const end = Math.min(start + CHUNK_SIZE, file.size);
        const chunk = await file.slice(start, end).arrayBuffer();
        await uploadFileChunk(uploadId, chunk, start, end, file.size);

        setUploadProgress(prev => ({
          ...prev,
          [questionNum]: {
            ...prev[questionNum],
            [file.name]: Math.round((end / file.size) * 100)
          }
        }));
      }

      const { fileId } = await completeFileUpload(uploadId, folderGoogleId);
      return fileId;
    } catch (error) {
      console.error("Error uploading file:", error);
      throw error;
    }
  };

  const handleSubmit = async () => {
    console.log(data);
    console.log(uploadedFiles);

    if (formError && formError.length > 0) {
      alert("Please fix the errors in the form before submitting.");
      console.log(formError);
      console.log(schema);
      console.log(uischema);
      return;
    }

    const fileUploadError = validateFileUpload();
    if (fileUploadError) {
      alert(`File upload is required for question(s): ${fileUploadError}`);
      return;
    }

    setIsUploading(true);
    try {
      const fileUploads = {};
      for (const [questionNum, files] of Object.entries(uploadedFiles)) {
        fileUploads[questionNum] = [];
        for (const file of files) {
          const fileId = await uploadFile(file, questionNum);
          fileUploads[questionNum].push({ name: file.name, id: fileId });
        }
      }

      const responseData = {
        responseData: data,
        formStructure: formStructure,
      };

      const response = await postResponse(responseData, folderGoogleId);
      if (response.status === 200) {
        alert("Form response submitted successfully!");
        submitButtonFunction();
      } else {
        alert("Failed to submit form response. Error: " + response.status + "\n" + response.data.message);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      alert("An error occurred while submitting the form. Please try again.\n" + error);
    } finally {
      setIsUploading(false);
      setUploadProgress({});
    }

  };

  return (
    <div className="container mt-5">
      {formStructure && schema && uischema && (
        <div className="row justify-content-center">
          <div className="col-md-8">
            <div className="card">
              <div className="card-header">
                <h3>{formStructure.title}</h3>
                <p>{formStructure.description}</p>
              </div>
              <div className="card-body">
                {serverError && <div className="alert alert-danger">{serverError}</div>}
                {!formStructure && <div>Loading...</div>}
                <JsonForms
                  schema={schema}
                  uischema={uischema}
                  renderers={materialRenderers}
                  data={data}
                  onChange={({ errors, data }) => {
                    setData(data);
                    setFormError(errors);
                  }}
                />

                {fileUploadSchema && fileUploadSchema.length > 0 && (
                  <>
                    {fileUploadSchema.map((fileUpload, index) => (
                      <div className="mt-4" key={index}>
                        <label className="form-label" htmlFor={`customFile-${index}`}>
                          {fileUpload.title}
                          <p className="text-muted" style={{ fontSize: "0.8rem" }}>
                            Allowed file types: {fileUpload.fileTypes.join(", ")}
                          </p>
                        </label>
                        <input
                          type="file"
                          className="form-control"
                          id={`customFile-${index}`}
                          accept={fileUpload.fileTypes.join(", ")}
                          onChange={(event) => handleFileChange(event, fileUpload.questionNumber)}
                          multiple
                        />
                        {fileUpload.isRequired && <p className="text-danger" style={{ fontSize: "0.8rem", paddingLeft: "0.5rem" }}>Required</p>}
                        {uploadProgress[fileUpload.questionNumber] && (
                          <div className="mt-2">
                            {Object.entries(uploadProgress[fileUpload.questionNumber]).map(([fileName, progress]) => (
                              <div key={fileName}>
                                {fileName}: {progress}%
                                <div className="progress">
                                  <div
                                    className="progress-bar"
                                    role="progressbar"
                                    style={{ width: `${progress}%` }}
                                    aria-valuenow={progress}
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                  ></div>
                                </div>
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    ))}
                  </>
                )}
                <div className="mt-4">
                  <button type="button" className="btn btn-primary"
                    onClick={() => handleSubmit()} disabled={isUploading}>
                    {isUploading ? "Uploading..." : "Submit"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default JsonFormsPage;
