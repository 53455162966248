import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { getFormById } from "../apis/formsApis";
import { FaAngleDown } from "react-icons/fa6";
import "../styles/FormViewStyles.css";

const ViewFormModal = ({ show, onHide, formID, setViewFormModal }) => {
  const [form, setForm] = useState(null);
  const [profile, setProfile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [expandedQuestions, setExpandedQuestions] = useState([]);

  const [isMobile, setIsMobile] = useState(window.innerWidth < 750);

  useEffect(() => {
    if (show) {
      const userFromStorage = JSON.parse(localStorage.getItem("user"));
      if (userFromStorage) {
        setProfile(userFromStorage);
        setViewFormModal(200);
        window.addEventListener("resize", handleResize);
      } else {
        setViewFormModal(401);
        onHide();
      }
    }
  }, [show, onHide, setViewFormModal]);

  const handleResize = () => {
    setIsMobile(window.innerWidth < 750);
  };

  useEffect(() => {
    if (show && profile) {
      fetchForm();
    }
  }, [show, profile]);

  const getQuestionTypeText = (questionType) => {
    console.log(questionType);
    switch (questionType) {
      case "shortAnswer":
        return "Short Answer";
      case "paragraph":
        return "Paragraph";
      case "checkboxes":
        return "Checkboxes";
      case "multipleChoice":
        return "Multiple Choice";
      case "dropdown":
        return "Dropdown";
      case "date":
        return "Date";
      case "time":
        return "Time";
      case "fileUpload":
        return "File Upload";
      case "scale":
        return "Scale";
      case "numeric":
        return "Numeric";
      case "group":
        return "Group";
      default:
        return "Unknown";
    }
  };

  const fetchForm = async () => {
    setIsLoading(true);
    const response = await getFormById(formID);

    if (response.status >= 200 && response.status < 300) {
      const formData = response.data;
      setForm(formData);
    } else {
      console.log(" Error: ", response);
      setViewFormModal(response.status);
      onHide();
    }
    setIsLoading(false);
  };

  const resetState = () => {
    setForm(null);
    setProfile(null);
    setIsLoading(false);
  };

  const renderQuestion = (question, index, parentIndex = null) => {
    const questionNumber = parentIndex !== null
      ? `${parentIndex + 1}.${index + 1}`
      : `${index + 1}`;

    return (
      <div key={questionNumber} className="questions-container">
        <div className="question-header"
          onClick={() => setExpandedQuestions((prevState) =>
            prevState.includes(questionNumber)
              ? prevState.filter((i) => i !== questionNumber)
              : [...prevState, questionNumber]
          )}
          onKeyDown={() => setExpandedQuestions((prevState) =>
            prevState.includes(questionNumber)
              ? prevState.filter((i) => i !== questionNumber)
              : [...prevState, questionNumber]
          )} role="button" tabIndex="0">
          <strong style={{ fontSize: isMobile ? "12px" : "18px" }}>
            Question {questionNumber}:
          </strong>{" "}
          <span style={{ fontSize: isMobile ? "12px" : "18px", maxWidth: "100%", paddingLeft: "10px", textWrap: "nowrap", overflowX: "auto" }}>
            {question.title}
          </span>
          <FaAngleDown className={`expanded-icon ${expandedQuestions.includes(questionNumber) ? "expanded" : ""}`} />
        </div>
        {expandedQuestions.includes(questionNumber) && (
          <>
            <div className="field-attribute-container-in-form">
              <strong style={{ fontSize: isMobile ? "12px" : "16px" }}>Question Title:</strong>{" "}
              <span style={{ fontSize: isMobile ? "10px" : "14px", maxWidth: "100%", paddingLeft: "10px", textWrap: "nowrap", overflowX: "auto" }} title={question.title}>
                {question.title}
              </span>
            </div>
            <div className="field-attribute-container-in-form">
              <strong style={{ fontSize: isMobile ? "12px" : "16px" }}>Question Type:</strong>{" "}
              <span style={{ fontSize: isMobile ? "10px" : "14px", paddingLeft: "10px" }} title={getQuestionTypeText(question.type)}>
                {getQuestionTypeText(question.type)}
              </span>
            </div>
            <div className="field-attribute-container-in-form">
              <strong style={{ fontSize: isMobile ? "12px" : "16px" }}>Question Required:</strong>{" "}
              <span style={{ fontSize: isMobile ? "10px" : "14px", paddingLeft: "10px" }}>
                {question.isRequired ? "Yes" : "No"}
              </span>
            </div>
            {(question.type === "checkboxes" || question.type === "multipleChoice" || question.type === "dropdown") && (
              <div className="field-attribute-container-in-form" style={{ display: "block" }}>
                <p style={{ fontSize: isMobile ? "12px" : "16px", marginBottom: "0" }}><strong>Choices:</strong></p>
                <ul style={{ listStyleType: "none", paddingLeft: 0 }}>
                  {question.choices.map((choice, index) => (
                    <div key={index} className="field-list-item-container">
                      {" > "}
                      <span style={{ fontSize: isMobile ? "10px" : "" }}>
                        {choice}
                      </span>
                    </div>
                  ))}
                </ul>
              </div>
            )}
            {question.type === "fileUpload" && (
              <div className="field-attribute-container-in-form" style={{ display: "block" }}>
                <p style={{ fontSize: isMobile ? "12px" : "16px", marginBottom: "0" }}><strong>File Types Allowed:</strong></p>
                <ul style={{ listStyleType: "none", paddingLeft: 0 }}>
                  {question.fileTypes.map((fileType, index) => (
                    <div key={index} className="field-list-item-container">
                      {" > "}
                      <span style={{ fontSize: isMobile ? "10px" : "" }}>
                        {fileType}
                      </span>
                    </div>
                  ))}
                </ul>
              </div>
            )}
            {question.type === "scale" && (
              <div className="field-attribute-container" style={{ display: "block" }}>
                <p style={{ fontSize: isMobile ? "12px" : "20px", marginBottom: "0" }}><strong>Range: </strong></p>
                <div className="field-attribute-container">
                  <span style={{ fontSize: isMobile ? "10px" : "16px" }}><strong>Minimum:</strong> {question.minNumber}</span>
                </div>
                <div className="field-attribute-container">
                  <span style={{ fontSize: isMobile ? "10px" : "16px" }}><strong>Maximum:</strong> {question.maxNumber}</span>
                </div>
              </div>
            )}
            {question.type === "group" && (
              <div className="field-attribute-container-in-form" style={{ display: "block" }}>
                <p style={{ fontSize: isMobile ? "12px" : "16px", marginBottom: "0" }}><strong>Sub-Questions:</strong></p>
                {question.questions.map((subQuestion, subIndex) => renderQuestion(subQuestion, subIndex, index))}
              </div>
            )}
          </>
        )}
      </div>
    );
  };

  return (
    <Modal show={show} onHide={() => {
      resetState();
      onHide();
    }} centered dialogClassName="modal-dialog-centered" size="lg">
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          View Form
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ maxHeight: "650px", overflowY: "auto", maxWidth: "800px", overflowX: "auto" }}>
        {isLoading ? (
          <div className="text-center">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        ) : form ? (
          <div>
            <div className="form-attribute-container">
              <strong style={{ fontSize: isMobile ? "14px" : "20px" }}>From Name:</strong>{" "}
              <span style={{ fontSize: isMobile ? "14px" : "18px", maxWidth: "100%", paddingLeft: "10px", textWrap: "nowrap", overflowX: "auto" }}>
                {form.name}
              </span>
            </div>
            <div className="form-attribute-container">
              <strong style={{ fontSize: isMobile ? "14px" : "20px" }}>Title:</strong>{" "}
              <span style={{ fontSize: isMobile ? "14px" : "18px", maxWidth: "100%", paddingLeft: "10px", textWrap: "nowrap", overflowX: "auto" }}>
                {form.structure.title}
              </span>
            </div>
            <div className="form-attribute-container">
              <strong style={{ fontSize: isMobile ? "14px" : "20px" }}>Description:</strong>{" "}
              <span style={{ fontSize: isMobile ? "14px" : "18px", maxWidth: "100%", paddingLeft: "10px", textWrap: "nowrap", overflowX: "auto" }}>
                {form.description}
              </span>
            </div>
            <div className="questionContainer">
              <strong style={{ fontSize: isMobile ? "14px" : "20px" }}>Questions:</strong>
              <div>
                {form.structure.questions.map((question, index) => renderQuestion(question, index))}
              </div>
            </div>
          </div>
        ) : (
          <div className="text-center">
            <h3>No form found</h3>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default ViewFormModal;